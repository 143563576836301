import React, { useEffect, useState } from 'react';
import { DataGrid, GridRowId, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { FaEdit, FaFileWord, FaTrash } from 'react-icons/fa';
import { Button, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { createOrderStatusLog, deleteOrder, editOrderStatus, getAllOrderStatuses, getAllOrders, getOrders } from '../../server/server';
import DownloadSnapshot from './DownloadSnapshot';
import { fabric } from 'fabric';
import { jsPDF } from 'jspdf';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { Order, OrderStatusEnum, PaymentStatusEnum, ShippingStatusEnum } from './OrderInterfaces';
import { useUser } from '../../auth/UseUser';

function Orders() {
	const [orders, setOrders] = useState<Order[]>([]);
	const [filteredOrders, setFilteredOrders] = useState<Order[]>(orders || []);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 50,
	});
	const [rowCountState, setRowCountState] = useState(50);
	const [isLoading, setIsLoading] = useState(true);


	const [filter, setFilter] = useState('all');
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [dateType, setDateType] = useState<'createdAt' | 'fulfilledAt'>('createdAt');
	const [selectedStatus, setSelectedStatus] = useState<OrderStatusEnum | string>('None');
	const [selectedBulkAction, setSelectedBulkAction] = useState<OrderStatusEnum | string>('None');
	const [term, setTerm] = useState('')
	const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

	const { user } = useUser();

	const mapOrderStatus = (status: string) => {
		const enumValue = OrderStatusEnum[status as keyof typeof OrderStatusEnum];
		return enumValue || status;
	};

	// Function to map payment status code to enum value
	const mapPaymentStatus = (status: string) => {
		return PaymentStatusEnum[status as keyof typeof PaymentStatusEnum] || status;
	};

	// Function to map shipping status code to enum value
	const mapShippingStatus = (status: string) => {
		return ShippingStatusEnum[status as keyof typeof ShippingStatusEnum] || status;
	};

	const navigate = useNavigate();

	const downloadSnapshot = (canvas: any, area: any) => {
		// Create a temporary canvas to draw the cropped image
		const tempCanvas = document.createElement('canvas');
		tempCanvas.width = area.width;
		tempCanvas.height = area.height;
		const ctx = tempCanvas.getContext('2d');

		// Export the entire fabric canvas to an image
		const img = new Image();
		img.src = canvas.toDataURL({
			format: 'png',
		});

		// Once the image is loaded, draw the cropped part to the temporary canvas
		img.onload = () => {
			ctx?.drawImage(img, area.left, area.top, area.width, area.height, 0, 0, area.width, area.height);

			// Create a PDF and add the cropped image
			const doc = new jsPDF('p', 'mm', [area.width, area.height]);
			doc.addImage(tempCanvas.toDataURL('image/png'), 'PNG', 0, 0, area.width, area.height);

			// Save the PDF
			doc.save('snapshot.pdf');
		};
	};

	const loadOrders = async (offset = 0) => {
		setIsLoading(true);

		const resp = await getAllOrders(term, paginationModel.pageSize, offset);

		if (resp.status == 200) {
			setOrders(resp.data.items);
			setFilteredOrders(resp.data.items);
			setRowCountState(resp.data.total);
			setIsLoading(false);
		}
		console.log(orders);
		console.log(filteredOrders);
	};

	useEffect(() => {
		loadOrders(paginationModel.page * paginationModel.pageSize);
	}, [term, paginationModel]);


	const viewDetails = (id: GridRowId) => {
		console.log('view details', id, orders);
		const selectedOrder = orders?.find((order) => order?.id === id);
		navigate(`/orders/${selectedOrder?.id}`);
	};

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);

		if (date) {
			const filtered = orders?.filter((order) => {
				const orderDate = dateType === 'createdAt' ? new Date(order.createdAt) : order.fulfilledAt ? new Date(order.fulfilledAt) : null;
				return orderDate ? orderDate.toDateString() === date.toDateString() : false;
			});
			setFilteredOrders(filtered);
			setRowCountState(filtered.length);
		} else {
			setFilteredOrders(orders);
			setRowCountState(orders?.length);
		}
	};

	const handleDateTypeChange = (event: any) => {
		setDateType(event.target.value);
	};

	const handleStatusChange = (event: any) => {
		const statusString = event.target.value.trim();
		const statusEnum = OrderStatusEnum[statusString as keyof typeof OrderStatusEnum];

		setSelectedStatus(event.target.value);

		if (statusString === 'None') {
			setFilteredOrders(orders);
			setRowCountState(orders?.length);
		} else {
			const filtered = orders.filter((order) => {
				return order.orderStatus === statusEnum;
			});

			setFilteredOrders(filtered);
			setRowCountState(filtered?.length);
		}
	};
	const handleCustomerFilter = (customerId: GridRowId) => {
		const filtered = orders.filter((order) => order.customer.id === customerId);
		setFilteredOrders(filtered);
		setRowCountState(filtered.length);
	};

	const handleAllButtonClick = () => {
		setFilteredOrders(orders);
		setRowCountState(orders?.length);
		setSelectedStatus('None');
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: GridRowId) => {
		if (event.target.checked) {
			setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
		} else {
			setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((rowId) => rowId !== id));
		}
	};

	const handleBulkActionChange = async (event: any) => {
		const action = event.target.value;
		setSelectedBulkAction(event.target.value);

		console.log('usao');

		switch (action) {
			case 'None':
				// Do nothing
				break;
			case 'delete':
				// Delete all orders in the selectedRows array
				for (const orderId of selectedRows) {
					const resp = await deleteOrder(orderId);
					console.log('delete resp', resp);
					if ((resp.status = 200)) {
						alert(`Order ${orderId} moved to trash`);
						loadOrders();
					}
				}
				break;
			default:
				// Update the status of all orders in the selectedRows array
				for (const orderId of selectedRows) {
					// Find the order in the ordersList
					const order = orders?.find((order) => order.id === orderId);
					if (!order) continue;

					let info = {
						orderStatus: OrderStatusEnum[action as keyof typeof OrderStatusEnum],
						paymentStatus: PaymentStatusEnum[action as keyof typeof PaymentStatusEnum],
						shippingStatus: ShippingStatusEnum[action as keyof typeof ShippingStatusEnum],
					};

					const resp = await editOrderStatus(orderId, info);

					if (resp.status == 200) {
						let newLog = {
							oldStatus: order.orderStatus,
							newStatus: OrderStatusEnum[action as keyof typeof OrderStatusEnum],
							orderId,
							userId: user.id,
						};

						const logResp = await createOrderStatusLog(newLog);

						if (logResp.status == 200) {
							alert(`Status of order ${orderId} updated to ${action}`);
							loadOrders();
						} else {
							alert(`Error creating status log for order ${orderId}:${logResp.status}`);
						}
					} else {
						alert(`Error updating status of order ${orderId}:${resp.status}`);
					}
				}
				break;
		}
	};
	const columns: GridColDef[] = [
		{
			field: 'checkbox',
			headerName: 'Select',
			sortable: false,
			disableColumnMenu: true,
			width: 60,

			renderCell: (params) => <input type="checkbox" checked={selectedRows.includes(params.id)} onChange={(event) => handleCheckboxChange(event, params.id)} />,
		},
		{
			field: 'orderNo', headerName: 'Order Id', width: 100, sortable: false,
		},
		{
			field: 'customer',
			headerName: 'Customer',
			flex: 1,
			sortable: false,
			minWidth: 170,
			renderCell: (params) => (
				<Button variant="text" color="primary" onClick={() => handleCustomerFilter(params?.row?.customer?.id)}>
					{`${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`}
				</Button>
			),
		},
		{
			field: 'numberOfItems', sortable: false,
			width: 50, headerName: 'Items', valueGetter: (params) => params?.row?.numberOfItems
		},
		{
			field: 'totalValue',
			headerName: 'Total',
			sortable: false,
			width: 150,
			valueGetter: (params) => `${params?.row?.totalValue} ${params?.row?.currency?.symbol}`,
		},
		{
			field: 'paymentStatus',
			sortable: false,
			headerName: 'Payment',
			valueGetter: (params) => mapPaymentStatus(params?.row?.paymentStatus),
		},
		{
			field: 'shippingStatus',
			headerName: 'Ship',
			sortable: false,
			minWidth: 200,
			valueGetter: (params) => mapShippingStatus(params?.row?.shippingStatus),
		},
		{
			field: 'createdAt',
			sortable: false,
			headerName: 'Date',
			valueGetter: (params) => {
				const originalDate = new Date(params?.row?.createdAt);
				return originalDate.toLocaleDateString('en-GB');
			},
		},
		// {
		// 	field: 'zone',
		// 	headerName: 'Zone',
		// 	minWidth: 150,
		// 	valueGetter: (params) => `${params?.row?.shippingOption?.zone?.name}`,
		// },
		{
			field: 'orderStatus',
			headerName: 'Status',
			sortable: false,
			valueGetter: (params) => mapOrderStatus(params?.row?.orderStatus),
		},
		// {
		// 	field: 'actions',
		// 	type: 'actions',
		// 	headerName: 'View Details',
		// 	width: 200,
		// 	getActions: (params: any) => [
		// 		<Button variant="text" color="primary" onClick={() => viewDetails(params?.row?.id)}>
		// 			View Details
		// 		</Button>,
		// 	],
		// },
		// {
		// 	field: 'actions',
		// 	type: 'actions',
		// 	width: 120,
		// 	 getActions: (params) => [<GridActionsCellItem icon={<FaFileWord style={{ width: 20, height: 20 }} />} label="Word" onClick={() => viewOrder(params.id, params.row.design, params.row.restricted)} />],
		// },
		{
			field: 'shop',
			sortable: false,
			headerName: 'Shop',
			valueGetter: (params) => `${params?.row?.origin}`,
		},
	];

	return (
		<div className=" px-4 sm:px-4 w-full">
			<div className="py-10 h-full w-full">
				<div className="flex flex-col gap-6">
					<h1 className="text-black text-4xl font-bold">Order Management</h1>
					{/* <Button variant="contained" color="primary" style={{ backgroundColor: '#21187F', color: 'white' }}>
					Manage Orders
				</Button> */}
				</div>
				<div className="rounded-lg border border-[#81848f3d] min-w-full p-8 pb-4 mt-8">
					<div className="flex flex-col md:flex-row items-center gap-4 overflow-x-auto">
						<div className="mr-4">
							<TextField
								id="outlined-required"
								placeholder="Search order id"
								onChange={(e) => setTerm(e.target.value)}
							/>
						</div>
						<div className="mr-4">
							<Select value={selectedBulkAction} onChange={handleBulkActionChange}>
								<MenuItem value="None">Bulk actions</MenuItem>
								<MenuItem value="delete">Move to trash</MenuItem>
								{Object.values(OrderStatusEnum)
									.filter((status) => typeof status === 'string')
									.map((status) => (
										<MenuItem key={status} value={status}>
											Change status to {status}
										</MenuItem>
									))}
							</Select>
						</div>
						<div className=" mr-4 ">
							<Button variant="contained" className="h-[50px]  truncate" style={{ backgroundColor: 'white', color: 'black' }} onClick={handleAllButtonClick}>
								All orders ({orders?.length})
							</Button>
						</div>
						<div className="mr-4">
							<Select value={selectedStatus} onChange={handleStatusChange}>
								<MenuItem value="None">Select order status</MenuItem>
								{Object.values(OrderStatusEnum)
									.filter((status) => typeof status === 'string')
									.map((status) => (
										<MenuItem key={status} value={status}>
											{status}
										</MenuItem>
									))}
							</Select>
						</div>
						<div className="flex gap-2">
							<DatePicker value={selectedDate} onChange={(date) => handleDateChange(date)} />
							<Select value={dateType} onChange={handleDateTypeChange} displayEmpty inputProps={{ 'aria-label': 'Date Type' }}>
								<MenuItem value="createdAt">Created At</MenuItem>
								<MenuItem value="fulfilledAt">Fulfilled At</MenuItem>
							</Select>
						</div>
					</div>
					<div /*style={{ height: filteredOrders.length === 0 || isLoading ? '300px' : 'auto' }}*/ className="mt-5 w-full h-1/2 overflow-x-auto">
						<DataGrid
							autoHeight
							className="w-full"
							rows={filteredOrders}
							columns={columns}
							rowCount={rowCountState}
							loading={isLoading}
							pageSizeOptions={[50]}
							paginationModel={paginationModel}
							disableRowSelectionOnClick
							onCellClick={(params) => params.field === "checkbox" ? '' : viewDetails(params?.row?.id)}
							paginationMode="server"
							onPaginationModelChange={(newModel) => {
								if (newModel.pageSize !== paginationModel.pageSize) {
									setPaginationModel({
										page: 0,
										pageSize: newModel.pageSize,
									});
									return;
								} else {
									setPaginationModel(newModel);
								}
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Orders;
