import React, { useEffect, useState } from 'react';
import NewProductsTabsHeader from './NewProductsTabsHeader';
import ProductInformations from './ProductInformations';
import Personalization from './Personalization';
import ProductComercialOptions from './ProductComercialOptions';
import { CustomAllow, ProductType } from '../../../model/enums';
import { getAdminProductById, getProductById, insertProduct, updateProduct } from '../../../server/server';
import { useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_RESTRICTED_AREA, isJSON } from '../../../Constants';
import { FaChevronLeft } from 'react-icons/fa';

export type Product = {
	id?: string;
	name?: string;
	sku?: string;
	tags?: string[];
	digitalProductFields?: any;
	web?: boolean;
	mobile?: boolean;
	featured?: boolean;
	description?: string;
	specification?: string;
	categories?: string[];
	zone?: any;
	language?: any;
	options?: {
		allowPersonalization?: CustomAllow;
		productType?: ProductType | undefined;
		image?: boolean | undefined;
		symbol?: boolean | undefined;
		text?: boolean | undefined;
		method?: string | undefined;
		engravingColor?: string | undefined;
	};
	draft?: boolean;
	disabled?: boolean;
	restricted?: number[];
	pdfW?: number | null;
	pdfH?: number | null;
	restrictedImage?: string | null;
	files?: any[];
	commercialOptions?: any;
	urlSlug?: any;
};

export const initialProduct: Product = {
	name: '',
	sku: '',
	tags: [],
	web: false,
	mobile: false,
	featured: true,
	description: '',
	specification: '',
	categories: [],
	zone: undefined,
	language: undefined,
	options: {
		allowPersonalization: CustomAllow.YES,
		productType: ProductType.GLASS,
		image: false,
		text: false,
		symbol: false,
	},
	draft: false,
	disabled: false,
	restricted: DEFAULT_RESTRICTED_AREA,
	restrictedImage: null,
	pdfW: null,
	pdfH: null,
	files: [],
	urlSlug: null,
};

function NewProduct() {
	let { id } = useParams();
	const [selectedTab, setSelectedTab] = useState('info');
	const [product, setProduct] = useState<Product>(initialProduct);
	const [drafted, setDrafted] = useState<boolean | undefined>(false);
	const [error, setError] = useState<any>({});
	const navigation = useNavigate();

	useEffect(() => {
		if (id != 'new') loadProduct();
	}, []);

	const loadProduct = async () => {
		const resp = await getAdminProductById(id);
		if (resp.status === 200) {
			let productData = resp.data;
			productData.restrictedImage = productData.restrictedImageId;
			delete productData.restrictedImageId;
			if (!productData.options) productData.options = initialProduct.options;
			console.log(productData);

			setProduct({ ...productData, files: reorderImages(productData.files), categories: productData.categories?.map((item: any) => item.id) });
			setDrafted(product.draft);
		}
	};

	const reorderImages = (files: any) => {
		return !!files && files[0]
			? files.map((item: any) => {
					return { id: item?.fileId, order: item?.order, mimeType: item?.mimeType };
			  })
			: [];
	};

	const onSubmit = (draft = false) => {
		if (!checkIfIsValid()) {
			alert('You should fill in all mandatory fields.');
			return;
		}
		let newProduct: Product = {};
		Object.assign(newProduct, product);
		let newOptions: any = {
			allowPersonalization: product.options?.allowPersonalization,
		};
		if (product.options?.allowPersonalization == CustomAllow.YES) {
			newOptions.productType = product.options.productType;
			newOptions.text = product.options.text;
			newOptions.symbol = product.options.symbol;
			newOptions.image = product.options.image;
			newOptions.method = product.options?.method;
			newOptions.engravingColor = product.options?.engravingColor;
		} else {
			//treba  postaviti na undefined??
			newOptions.productType = null; //product.options?.productType;
			newOptions.text = null; //product.options?.text;
			newOptions.image = null; //product.options?.image;
		}
		newProduct.options = newOptions;
		newProduct.language = product.language?.id;
		newProduct.zone = product.zone?.id;

		newProduct.urlSlug = product.urlSlug;

		newProduct.description = product.description;
		newProduct.specification = product.specification;

		newProduct.categories = product.categories;
		newProduct.draft = draft;
		newProduct.tags = !!product.tags ? product.tags : [];

		if (!newProduct.id) {
			newProduct.files = !!product.files ? product.files : [];
			console.log('submit data ', newProduct);
			createNewProduct(newProduct);
		} else {
			editProduct(newProduct);
		}
	};

	const createNewProduct = async (newProduct: any) => {
		const response = await insertProduct(newProduct);
		if (response.status === 201) {
			const productData = response.data;
			setProduct({ ...product, id: productData.id });
			setDrafted(productData.draft);
			alert(`The${productData.draft ? ' draft' : ''} product was successfully created`);
		}
	};
	const editProduct = async (newProduct: any) => {
		let updatedProduct = { ...newProduct };
		delete updatedProduct.deletedAt;
		delete updatedProduct.created;
		delete updatedProduct.commercialOptions;
		delete updatedProduct.seo;
		delete updatedProduct.groups;
		delete updatedProduct.files;
		updatedProduct.files = reorderImageOrdersEdit(product.files);
		const response = await updateProduct(updatedProduct);
		if (response.status === 201) {
			const productData = response.data;
			setProduct({ ...product, id: productData.id, description: productData.description, specification: productData.specification });
			setDrafted(productData.draft);
			alert(`The${productData.draft ? ' draft' : ''} product was successfully updated`);
		}
	};

	const reorderImageOrdersEdit = (list: any) => {
		return !!list
			? list.map((item: any) => {
					return { id: item?.id, order: item?.order };
			  })
			: [];
	};

	const checkIfIsValid = () => {
		let valid = true;
		if (!product.name) {
			setError((e: any) => ({ ...e, name: 'Required filed ' }));
			valid = false;
		}

		if (error.sku === 'Sku already exists') {
			valid = false;
		}

		if (!product.sku) {
			setError((e: any) => ({ ...e, sku: 'Required filed ' }));
			valid = false;
		}

		if (!product.language) {
			setError((e: any) => ({ ...e, language: 'Required filed ' }));
			valid = false;
		}
		if (!product.zone) {
			setError((e: any) => ({ ...e, zone: 'Required filed ' }));
			valid = false;
		}

		if (!product.urlSlug) {
			setError((e: any) => ({ ...e, urlSlug: 'Required filed ' }));
			valid = false;
		}

		return valid;
	};

	const goBack = () => {
		navigation(-1);
	};

	return (
		<div className="py-[20px] px-2 h-full">
			<div className="flex flex-row items-center gap-5 rounded-md shadow-darkGrey shadow-md mb-6">
				<button onClick={goBack} className="flex flex-row gap-2 items-center ml-5">
					<FaChevronLeft /> Back
				</button>
				<div className="text-2xl font-semibold text-black p-5 ">
					{!product?.id ? 'Add new product' : 'Edit product'} <span className="text-base">{!!product?.id ? (drafted ? '(Draft)' : '(Published)') : ''}</span>
				</div>
			</div>
			<NewProductsTabsHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
			{selectedTab === 'info' && <ProductInformations product={product} setProduct={setProduct} onSubmit={onSubmit} error={error} setError={setError} />}
			{selectedTab === 'personalization' && <Personalization product={product} setProduct={setProduct} onSubmit={onSubmit} />}
			{selectedTab === 'options' && <ProductComercialOptions product={product} setProduct={setProduct} />}
		</div>
	);
}

export default NewProduct;
