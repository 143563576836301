import React from 'react';
import { Oval } from 'react-loader-spinner';

function Loading({ secondaryColor = '#f1f1fe', color = 'rgb(248, 157, 189)', height = 80 }) {
	return <Oval secondaryColor={secondaryColor} color={color} height={height} width={height} strokeWidth={4} />;
	// return null
}

export default Loading;
//
