import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridRowId, GridActionsCellItem, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { FaClone, FaEdit, FaTrash } from 'react-icons/fa';
import { Button, TextField, Tabs, Tab } from '@mui/material';
import { cloneProduct, getAllProducts, getImage, deleteProduct, exportProducts } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';

import * as XLSX from 'xlsx';

interface Product {
	name: string;
	sku: string;
	price: number;
	category: string;
	created: Date;
}

function CustomToolbar({ products, page, pageSize, filter }: { products: Product[]; page: number; pageSize: number; filter: string }) {
	const exportToExcel = (data: any[], fileName: string) => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, fileName);
	};

	const exportAllData = async () => {
		const resp = await exportProducts(filter);

		if (resp.status == 200) {
			// console.log(resp.data);
			exportToExcel(resp.data, `${filter}_products.xlsx`);
		}
	};

	return (
		<GridToolbarContainer>
			{/* <Button onClick={exportVisibleData}>Export Excel (Table)</Button> */}
			<Button onClick={exportAllData}>Export Excel (By filter)</Button>
		</GridToolbarContainer>
	);
}

function Product() {
	let navigate = useNavigate();
	const [products, setProducts] = useState([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [rowCountState, setRowCountState] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [filter, setFilter] = useState('all');
	const [counts, setCounts] = useState({
		published: 0,
		deleted: 0,
		allItems: 0,
		draft: 0,
		featured: 0,
	});

	const publishedCount = counts?.published ?? 0;
	const deletedCount = counts?.deleted ?? 0;
	const allItemsCount = counts?.allItems ?? 0;
	const draftCount = counts.draft ?? 0;
	const featuredCount = counts.featured ?? 0;
	const searchDelay = 700;

	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, filter);
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery, filter]);

	const loadProducts = async (page: any, pageSize: any, searchQuery?: any, filter?: any) => {
		setIsLoading(true);
		const resp = await getAllProducts(pageSize, page * pageSize, searchQuery, filter);
		if (resp.status === 200) {
			setProducts(resp.data.products);
			setRowCountState(resp.data.total);
			setCounts(resp.data.counts);
			setIsLoading(false);
		} else {
		}
	};

	const handleDeleteProduct = async (id: string) => {
		const deleteResp = await deleteProduct(id);

		if (deleteResp.status == 200) {
			alert(`Product deleted successfully.`);
			loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, filter);
		} else {
			alert(`Error deleating product: ${deleteResp.status}`);
		}
	};

	const deleteRowProduct = (id: any) => {
		if (window.confirm('Do you want to delete this product?')) {
			setProducts((prevProducts) => prevProducts.filter((product: any) => product.id !== id));
			handleDeleteProduct(id);
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
	};
	const handleNewProduct = () => {
		navigate('/products/form/new');
	};

	const handleCloneProduct = async (id: any) => {
		if (window.confirm('Do you want to clone this product?')) {
			const resp = await cloneProduct(id);
			if (resp.status === 201) {
				loadProducts(paginationModel.page, paginationModel.pageSize, searchQuery, filter);
				alert('The product has been successfully cloned');
			} else {
				alert('Error when cloning product!');
			}
		}
	};

	const editProduct = (id: any) => {
		navigate(`/products/form/${id}`);
	};

	const handleFilterChange = (event: any, newValue: any) => {
		setFilter(newValue);
		loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, newValue);
	};

	const columns: GridColDef[] = [
		{
			field: 'file',
			headerName: 'Image',
			filterable: false,
			sortable: false,
			renderCell: (params) => {
				return params?.row?.mimeType?.includes('image') ? (
					<img style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={getImage(params.value)} alt="Not found" />
				) : (
					// <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60, width: 60 }}>
					<video style={{ pointerEvents: 'none', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} muted>
						<source src={getImage(params.value)} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					// </div>
				);
			},
		},
		{ field: 'name', headerName: 'Name', flex: 1 },
		{ field: 'sku', headerName: 'SKU' },
		{
			field: 'price',
			headerName: 'Price',
			valueGetter: (params) => `${params?.row?.price ?? 0}`,
			renderCell: (params) => {
				const price = params?.row?.price ?? 0;
				const currency = params?.row?.currency ?? '';
				return <span>{`${price} ${currency}`}</span>;
			},
		},
		{
			field: 'category',
			headerName: 'Category',
			valueGetter: (params) => {
				const categories = params.row?.categories;

				if (Array.isArray(categories)) {
					return categories.join(', ');
				}

				return '';
			},
			renderCell: (params) => {
				const categories = params.value;

				if (categories) {
					return <span>{categories}</span>;
				}

				return null;
			},
		},
		{
			field: 'created',
			headerName: 'Date',
			valueGetter: (params) => {
				const date = new Date(params.value);
				return date.toLocaleDateString('en-GB', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 100,
			getActions: (params) => {
				if (filter === 'deleted') {
					return []; // Return an empty array to disable all actions
				}

				return [
					<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editProduct(params.id)} />,
					<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowProduct(params.id)} />,
					<GridActionsCellItem icon={<FaClone style={{ width: 20, height: 20 }} />} label="Clone" onClick={() => handleCloneProduct(params.id)} />,
				];
			},
		},
	];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="h-full w-full">
				<div className="flex flex-col md:flex-row gap-6">
					<h1 className="text-black text-4xl font-bold">Product Management</h1>
					<Button variant="contained" color="primary" onClick={() => handleNewProduct()} style={{ backgroundColor: '#21187F', color: 'white' }}>
						Add New Product
					</Button>
				</div>
				<div className="rounded-lg border border-[#81848f3d] min-w-full p-8 py-4 mt-5">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="flex flex-col md:flex-row">
							<Tabs value={filter} onChange={handleFilterChange} classes={{ flexContainer: 'flex-wrap sm:flex-nowrap' }}>
								<Tab label={`All (${allItemsCount})`} value="all" />
								<Tab label={`Published (${publishedCount})`} value="published" />
								<Tab label={`Deleted(${deletedCount})`} value="deleted" />
								<Tab label={`Draft(${draftCount})`} value="draft" />
								<Tab label={`Featured(${featuredCount})`} value="featured" />
							</Tabs>
						</div>
						<TextField label="Search" variant="outlined" value={searchQuery} onChange={handleSearchQueryChange} style={{ marginRight: '5px' }} />
					</div>
					<div /*style={{ height: products.length === 0 || isLoading ? '300px' : 'auto' }}*/ className="mt-3 h-fit w-full overflow-x-auto small-gray-scroll">
						<DataGrid
							autoHeight
							className="p-3"
							rows={products}
							columns={columns}
							rowCount={rowCountState}
							loading={isLoading}
							pageSizeOptions={[10, 20, 30]}
							paginationModel={paginationModel}
							paginationMode="server"
							onPaginationModelChange={(newModel) => {
								setPaginationModel((prevModel) => ({
									...prevModel,
									page: newModel.page,
									pageSize: newModel.pageSize,
								}));
								//loadProducts(newModel.page, newModel.pageSize, debouncedQuery, filter);
							}}
							slots={{
								toolbar: () => <CustomToolbar products={products} page={paginationModel.page} pageSize={paginationModel.pageSize} filter={filter} />,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Product;
