import { Button, Input } from '@mui/material';
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { getFonts, getImage, uploadFile } from '../../server/server';
import { ProductDesign } from './ProductDesignForm';

import ConfigurationBody from './ConfigurationBody';
import { useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import { DELETE_IMAGE_STRING, FONTS, INITIAL_SCALE_FACTOR, VIRTUAL_CANVAS_SIZE, DEFAULT_TEXT_CONFIG, HideControls, DEFAULT_COLOR, DEFAULT_FONT_SIZE, HideControlsText } from '../../Constants';
import { FaPlus } from 'react-icons/fa';
import LayerInfoWidget from './LayerInfoWidget';
import InputFileButton from '../../common/InputFileButton';
import HEARTH_ from '../../assets/svg/Hearth';

const ProductDesignLayers = forwardRef(({ printingLayers = [], initLayers = [], productImage, editMode = false, changeImage = () => {} }: { printingLayers?: string[]; initLayers?: any[]; productImage: any; editMode?: boolean; changeImage?: () => void }, ref) => {
	const [loading, setLoading] = useState(false);
	const [init, setInit] = useState(false);
	const { editor, onReady } = useFabricJSEditor();
	const [layers, setLayers] = useState<any[]>([]);
	const [fonts, setFonts] = useState<any[]>([]);
	const imgElement = useRef(document.createElement('img')).current;
	const canvasContainerRef = useRef<HTMLDivElement | null>(null);
	const [image, setImage] = useState<string>('');
	const [initPreload, setInitPreload] = useState(!!initLayers?.length);
	const [imageFile, setImageFile] = useState<File>();
	const [selectedObject, setSelectedObject] = useState<any>(null);
	const [error, setError] = useState<any>({});
	const imageWidth = canvasContainerRef.current?.clientWidth ?? 0;
	const imageHeight = canvasContainerRef.current?.clientHeight ?? 0;
	const layerRefs = useRef<any>(layers);
	const drawPolygonOn = useRef<boolean>(false);
	const initPolygon = useRef<boolean>(false);
	const [scaleChange, setScaleChange] = useState(false);
	const scaleFactorRef = useRef(1);

	const getArea = () => {
		const scale = scaleFactorRef.current;
		// Return the margins
		return {
			width: imageWidth * scale,
			height: imageHeight * scale,
			top: 0,
			left: 0,
		};
	};
	const computeScaleFactor = (imgW: any) => {
		if (imgW === 0) {
			console.error('Image height is zero, cannot compute scale factor.');
			return 1;
		}
		const containerHeight = (canvasContainerRef?.current?.clientWidth || 0) / imgW;

		return containerHeight;
	};
	useEffect(() => {
		const loadImageLayers = async () => {
			if (!!productImage) {
				setImage(getImage(productImage?.id));
				setImageFile(productImage);
			}

			if (initLayers && scaleChange) {
				// setInitPreload(true);

				const processedLayers = await Promise.all(
					initLayers.map(async (layer, index) => {
						const objs = await new Promise<any[]>((resolve) => {
							// Casting to any[] to avoid TS error
							fabric?.util?.enlivenObjects(
								[layer.design],
								(enlivenedObjs: any) => {
									resolve(enlivenedObjs);
								},
								''
							);
						});
						const design = objs.map((obj) => {
							const fabricObj = new fabric.Object(obj);
							const scaleFactor = scaleFactorRef.current;

							fabricObj.set({
								scaleX: (fabricObj.scaleX || 1) * scaleFactor,
								scaleY: (fabricObj.scaleY || 1) * scaleFactor,
								left: (fabricObj.left || 0) * scaleFactor,
								top: (fabricObj.top || 0) * scaleFactor,
								name: `${index}`,
							});

							if (fabricObj.type === 'i-text') {
								fabricObj.set({ ...DEFAULT_TEXT_CONFIG, fill: fabricObj.fill, lockScalingFlip: true });
								fabricObj.setControlsVisibility(HideControls);
								fabricObj.setControlVisible('deleteControl', true);
							}

							return fabricObj;
						});

						return {
							...layer,
							type: layer.layerType,
							design: design,
							obj: design[0],
						};
					})
				);

				setLayers(processedLayers);

				processedLayers.forEach((layer) => {
					layer.design.forEach((obj: any) => {
						editor?.canvas?.add(obj);
					});
				});

				editor?.canvas?.requestRenderAll();
				setInitPreload(false);
			}
		};

		loadImageLayers();
	}, [productImage, initLayers, scaleChange]);

	// useEffect(() => {
	// 	if (initPreload) {
	// 		if (layers.length > 0) {
	// 			const fabricJSObjects: any = editor?.canvas?.getObjects();
	// 			// setLayers([
	// 			// 	...layers.map((layer, index) => {
	// 			// 		console.log(`Layer index: ${index}`);

	// 			// 		layer.obj = fabricJSObjects[index];
	// 			// 		layer.obj.set({ name: `${index}` });
	// 			// 		if (layer.type === 'label') {
	// 			// 			// console.log(`Namestam font size: ${layer.obj.fontSize * scale} ${layer.obj.fontSize}, ${layer.obj.scale} ${layer.obj.scaleX} ${layer.obj.scaleY}`);
	// 			// 			// layer.obj.set({ fontSize: layer.obj.fontSize * scale });
	// 			// 		}
	// 			// 		return layer;
	// 			// 	}),
	// 			// ]);
	// 		}
	// 		setInitPreload(false);
	// 	}
	// }, [layers, initPreload]);

	const loadFontsList = async () => {
		const resp = await getFonts();
		if (resp.status === 200) {
			setFonts(resp.data);
		}
	};

	const checkInvalid = () => {
		let valid = true;
		if (image === '') {
			setError((e: any) => ({ ...e, image: 'Image is required' }));
			valid = false;
		}
		if (layers.length === 0) {
			setError((e: any) => ({ ...e, layers: 'At least one layer is required' }));
			valid = false;
		}

		return !valid;
	};

	useImperativeHandle(ref, () => ({
		getData() {
			const fabricJSObjects: any = editor?.canvas?.getObjects();
			const scaleFactor = scaleFactorRef.current;
			const exportLayers = [
				...layers.map((layer, index) => {
					layer.obj = fabricJSObjects[index];
					layer.obj.set({
						scaleX: (layer.obj.scaleX || 1) / scaleFactor,
						scaleY: (layer.obj.scaleY || 1) / scaleFactor,
						left: (layer.obj.left || 0) / scaleFactor,
						top: (layer.obj.top || 0) / scaleFactor,
						name: `${index}`,
					});
					return layer;
				}),
			];
			return {
				layers: exportLayers,
				image,
				imageFile,
				invalid: checkInvalid(),
			};
		},
	}));

	const addCircle = (index: number) => {
		const circle = new fabric.Circle({
			top: 10,
			left: 10,
			radius: 50,
			fill: 'transparent',
			strokeWidth: 2,
			stroke: 'red',
			objectCaching: false,
			transparentCorners: false,
			selectable: true,
		});

		editor?.canvas?.add(circle);
		editor?.canvas?.requestRenderAll();
		let _layers = [...layers];
		_layers[index].obj = circle;
		setLayers(_layers);
		layerRefs.current = _layers;
	};
	const addRect = (index: number) => {
		const circle = new fabric.Rect({
			top: 10,
			left: 10,
			width: 200,
			height: 100,
			fill: 'transparent',
			strokeWidth: 2,
			stroke: 'red',
			objectCaching: false,
			transparentCorners: false,
			selectable: true,
		});

		editor?.canvas?.add(circle);
		editor?.canvas?.requestRenderAll();
		let _layers = [...layers];
		_layers[index].obj = circle;
		setLayers(_layers);
		layerRefs.current = _layers;
	};
	const addHearth = (index: number) => {
		console.log(HEARTH_);
		// fetch('Hearth.svg')
		// 	.then((response) => response.text())
		// 	.then((svgString) => {
		// 		console.log(svgString);

		// 		// Upravljajte SVG stringom kako želite
		// 	});

		addSVGCroppingArea(HEARTH_, index);
		// const circle = new fabric.Path(
		// 	'M 272.70141,238.71731C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731z ',
		// 	{
		// 		top: 10,
		// 		left: 10,
		// 		fill: 'transparent',
		// 		strokeWidth: 2,
		// 		stroke: 'red',
		// 		objectCaching: false,
		// 		transparentCorners: false,
		// 		selectable: true,
		// 	}
		// );

		// editor?.canvas?.add(circle);
		// editor?.canvas?.requestRenderAll();
		// let _layers = [...layers];
		// _layers[index].obj = circle;
		// setLayers(_layers);
		// layerRefs.current = _layers;
	};

	const addImage = async (file: any, index: number) => {
		if (file == null) {
			return;
		}

		const resp = await uploadFile({ file: file, selectable: 'false' });
		if (resp.status === 201) {
			const image = resp.data;
			fabric.Image.fromURL(
				getImage(image.id) as string,
				(fabricImage) => {
					// const fabricImage = new fabric.Image(imgElement);

					// Calculate the maximum scale of the image to fit within the restricted area
					const maxScaleX = (getArea().width - 50) / (fabricImage.width ?? 1);
					const maxScaleY = (getArea().height - 50) / (fabricImage.height ?? 1);
					const scale = Math.min(maxScaleX, maxScaleY);
					fabricImage?.set({
						left: 10,
						top: 10,
						scaleX: scale,
						scaleY: scale,
						lockScalingFlip: true,
						name: `${index}`,
					});

					editor?.canvas?.add(fabricImage);
					editor?.canvas?.requestRenderAll();
					let _layers = [...layers];
					_layers[index].obj = fabricImage;
					setLayers(_layers);
					layerRefs.current = _layers;
				},
				{ crossOrigin: 'Anonymous' }
			);
		}
	};
	const addSVGCroppingArea = (data: any, index: number) => {
		fabric.loadSVGFromString(data, (objects, options) => {
			const loadedObjects = fabric.util.groupSVGElements(objects, options);
			// Calculate the maximum scale of the image to fit within the restricted area
			const maxScaleX = (getArea().width - 50) / (loadedObjects.width ?? 1);
			const maxScaleY = (getArea().height - 50) / (loadedObjects.height ?? 1);
			const scale = Math.min(maxScaleX, maxScaleY);
			loadedObjects.set({
				left: 10,
				top: 10,
				scaleX: scale,
				scaleY: scale,
			});

			console.log('Dodao sam ovo ');

			editor?.canvas?.add(loadedObjects);
			editor?.canvas?.requestRenderAll();
			let _layers = [...layers];
			_layers[index].obj = loadedObjects;
			_layers[index].croppingType = 'svg';
			setLayers(_layers);
			layerRefs.current = _layers;
		});

		// imgElement.onload = () => {
		// 	const fabricImage = new fabric.Image(imgElement);
		// 	// Calculate the maximum scale of the image to fit within the restricted area
		// 	const maxScaleX = (getArea().width - 50) / (fabricImage.width ?? 1);
		// 	const maxScaleY = (getArea().height - 50) / (fabricImage.height ?? 1);
		// 	const scale = Math.min(maxScaleX, maxScaleY);
		// 	fabricImage?.set({
		// 		left: 10,
		// 		top: 10,
		// 		scaleX: scale,
		// 		scaleY: scale,
		// 		lockScalingFlip: true,
		// 		name: `${index}`,
		// 	});
		// 	editor?.canvas?.add(fabricImage);
		// 	editor?.canvas?.requestRenderAll();
		// 	let _layers = [...layers];
		// 	_layers[index].obj = fabricImage;
		// 	setLayers(_layers);
		// 	layerRefs.current = _layers;
		// };
	};

	const deleteObject = (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => {
		const target = transformData.target;
		const canvas = target?.canvas;
		if (canvas && target) {
			const layerId = target.name;
			console.log(`Layer ID: ${layerId}`);
			if (layerId) deleteLayer(parseInt(layerId ?? '0'));
			canvas?.remove(target);
			canvas?.requestRenderAll();
			return true;
		}
		return false;
	};

	useEffect(() => {
		loadFontsList();
	}, []);

	useEffect(() => {
		if (!loading && !!image) {
			imgElement.onload = () => {
				try {
					const fabricImage = new fabric.Image(imgElement);
					const imageW = fabricImage?.width ?? 1;
					const initScale = computeScaleFactor(imageW);
					scaleFactorRef.current = initScale;
					setScaleChange(true);
					console.log('curernt', initScale);
					fabricImage?.scale(initScale);
					editor?.canvas?.setWidth(fabricImage?.getScaledWidth());
					editor?.canvas?.setHeight(fabricImage?.getScaledHeight());
					editor?.canvas?.setBackgroundImage(fabricImage, editor?.canvas?.requestRenderAll.bind(editor?.canvas), {});
				} catch (e) {}
			};
			imgElement.src = image; //require('../../assets/images/krigla.png');
		}
	}, [loading, image]);

	useEffect(() => {
		if (!!editor) {
			setSelectedObject(editor?.canvas?.getActiveObject());
		}
		if (!!editor && !init) {
			editor?.canvas?.on('mouse:dblclick', function (e) {
				doubleClick(e);
			});
			editor?.canvas?.on('mouse:down', function (e) {
				mouseDown(e);
			});
			editor?.canvas?.on('mouse:down', function (e) {
				mouseDown(e);
			});

			setInit(true);
		}
	}, [editor]);

	const onAddText = ({ text, fontFamily = fonts[0]?.name ?? FONTS[0], layerIndex = 0 }: { text: string; fontFamily?: string; layerIndex?: number }) => {
		const textComponent = new fabric.Textbox(text, {
			...DEFAULT_TEXT_CONFIG,
			left: (getArea().left + 50) * scaleFactorRef.current,
			top: (getArea().top + 50) * scaleFactorRef.current,
			name: `${layerIndex}`,
			textAlign: 'center',
			splitByGrapheme: true,
			isWrapping: false,
			width: 300,
		});
		// textComponent.on('text:changed', function () {
		// 	var lines = textComponent?.text?.split('\n') ?? [];
		// 	if (lines.length > 1) {
		// 		textComponent.text = lines[0];
		// 		textComponent.exitEditing();
		// 		textComponent.enterEditing();
		// 	}
		// });

		// textComponent.onKeyDown = (e: KeyboardEvent) => {
		// 	if (e.code === 'Enter') {
		// 		e.preventDefault();
		// 	}
		// };

		textComponent.setControlsVisibility(HideControlsText);
		// textComponent.setControlVisible('deleteControl', true);
		textComponent.set({ fontFamily });
		editor?.canvas?.add(textComponent);
		editor?.canvas?.setActiveObject(textComponent);
		editor?.canvas?.requestRenderAll();
		return textComponent;
	};

	const addPolygon = ({ points }: { points: any }) => {
		const polygons = points;
		var polygon = new fabric.Polyline(polygons, {
			left: points[0].x * scaleFactorRef.current,
			top: points[0].y * scaleFactorRef.current,
			fill: 'transparent',
			strokeWidth: 2,
			stroke: 'red',
			objectCaching: false,
			transparentCorners: false,
			selectable: true,
			name: 'clippingArea',
		});
		polygon.setControlsVisibility(HideControls);
		// polygon.setControlVisible('deleteControl', true);
		editor?.canvas.add(polygon);
		return polygon;
	};

	const drawFreePolygon = (index: number) => {
		drawPolygonOn.current = true;
		initPolygon.current = true;
	};

	const handleProductFilesChange = async (file: File) => {
		setError((e: any) => ({ ...e, image: '' }));
		setImage(URL.createObjectURL(file));
		setImageFile(file);
		changeImage();
	};

	const addLayer = () => {
		const indexOfLastElement = layers?.length - 1;
		if (layers?.length > 0 && (!layers[indexOfLastElement].type || !layers[indexOfLastElement].obj)) return;
		setError((e: any) => ({ ...e, layers: '' }));
		setLayers([...layers, { order: layers.length, printingMethod: 'PRINTING' }]);
		layerRefs.current = [...layers, { printingMethod: 'PRINTING' }];
	};

	const deleteLayer = (index: number) => {
		editor?.canvas?.remove(layers[index].obj);
		editor?.canvas?.requestRenderAll();
		let _layers = [...layers];
		_layers.splice(index, 1);
		setLayers(_layers);
		layerRefs.current = _layers;
	};

	const changeType = (type: string, index: number) => {
		let _layers = [...layers];
		if (type === 'label') {
			const obj = onAddText({ text: 'New text', layerIndex: index });
			layerRefs?.current?.push(obj);
			_layers[index].obj = obj;
		} else if (type === 'croppingArea') {
		}
		_layers[index].type = type;
		setLayers(_layers);
		layerRefs.current = _layers;
	};

	const switchLayerEditable = (value: boolean, index: number) => {
		let _layers = [...layers];
		_layers[index].editable = value;
		setLayers(_layers);
		layerRefs.current = _layers;
	};

	const setLayerSelectedFonts = (fonts: string[], index: number) => {
		let _layers = [...layers];
		_layers[index].allowedFonts = fonts.map((e: any) => e.id);
		setLayers(_layers);
		layerRefs.current = _layers;
	};

	const switchPartiallyEditable = (value: boolean, index: number) => {
		let _layers = [...layers];
		_layers[index].partiallyEditable = value;
		setLayers(_layers);
		layerRefs.current = _layers;
	};
	const changePrintingLayer = (value: string, index: number) => {
		let _layers = [...layers];
		_layers[index].printingLayer = value;
		setLayers(_layers);
		layerRefs.current = _layers;
	};
	const changePrintingMethods = (value: string, index: number) => {
		let _layers = [...layers];
		_layers[index].printingMethod = value;
		setLayers(_layers);
		layerRefs.current = _layers;
	};

	const renderAll = () => editor?.canvas?.requestRenderAll();

	const mouseDown = (e: any) => {
		if (drawPolygonOn.current) getMouseCoords(e);
	};

	const doubleClick = (e: any) => {
		if (drawPolygonOn.current) mouseDoubleClick(e);
	};

	const getMouseCoords = (event: any) => {
		var pointer: any = editor?.canvas?.getPointer(event.e);
		var posX = pointer.x;
		var posY = pointer.y;
		if (initPolygon.current) {
			const obj = addPolygon({ points: [{ x: posX, y: posY }] });
			const layers = layerRefs.current;
			layerRefs.current[layers.length - 1].obj = obj;
			initPolygon.current = false;
		} else {
			const objects = editor?.canvas?.getObjects() ?? [];
			const obj: any = objects[objects.length - 1];
			obj?.points?.push({ x: posX, y: posY });
			const { width, height, left, top } = obj?._calcDimensions();
			obj.set({
				width,
				height,
				originX: 'left',
				originY: 'top',
				pathOffset: new fabric.Point(left + width / 2, top + height / 2),
			}).setCoords();
		}

		renderAll();
	};

	const mouseDoubleClick = (event: any) => {
		const objects = editor?.canvas?.getObjects() ?? [];
		const obj: any = objects[objects.length - 1];
		obj?.points?.push(obj?.points[0]);
		initPolygon.current = false;
		drawPolygonOn.current = false;
		const { width, height, left, top } = obj._calcDimensions();
		obj.set({
			width,
			height,
			originX: 'left',
			originY: 'top',
			pathOffset: new fabric.Point(left + width / 2, top + height / 2),
		}).setCoords();
		renderAll();
	};

	return (
		<div className="">
			<InputFileButton handleFile={handleProductFilesChange} text="Select Image preview" error={error.image} />
			<div className="grid grid-cols-2 gap-4 items-start  ">
				<div className="mt-[10px]">
					{!!image && (
						<>
							<Button variant="contained" color={!!error.layers ? 'error' : 'primary'} startIcon={<FaPlus />} className="mb-4" onClick={addLayer}>
								Add Layer
							</Button>
							{!!error.layers && <span className="error-labels-product-design">{error.layers}</span>}
						</>
					)}
					<div>
						{!initPreload &&
							layers.map((layer: any, index: number) => {
								// console.log(layer);

								const layersFunctions = {
									addImage: (file: any) => addImage(file, index),
									addSVGCroppingArea: (image: any) => addSVGCroppingArea(image, index),
									addCircle: () => addCircle(index),
									addRect: () => addRect(index),
									addHearth: () => addHearth(index),
									drawFreePolygon: () => drawFreePolygon(index),
									renderAll,
									switchLayerEditable: (value: boolean) => switchLayerEditable(value, index),
									setLayerSelectedFonts: (fonts: string[]) => setLayerSelectedFonts(fonts, index),
									switchPartiallyEditable: (value: boolean) => switchPartiallyEditable(value, index),
									changePrintingLayer: (value: string) => changePrintingLayer(value, index),
									changePrintingMethods: (value: string) => changePrintingMethods(value, index),
								};
								return (
									<LayerInfoWidget
										key={index}
										layer={layer}
										deleteLayer={() => deleteLayer(index)}
										changeType={(e) => changeType(e, index)}
										printingLayers={printingLayers}
										functions={layersFunctions}
										fabricObject={layer.obj}
										selectedObject={selectedObject?.name === layer.obj?.name}
										fonts={fonts}
									/>
								);
							})}
					</div>
				</div>
				<div className="min-h-[550px] mr-[10px]" ref={canvasContainerRef}>
					<ConfigurationBody onReady={onReady} loading={loading} />
				</div>
				<div style={{ height: 0, overflow: 'hidden' }}>
					{fonts.map((font) => (
						<span style={{ fontFamily: font.name }}>a</span>
					))}
				</div>
			</div>
		</div>
	);
});

export default ProductDesignLayers;
