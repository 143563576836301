import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import NewProductImagesForm from './NewProductImagesForm';
import NewProductSEOForm from './NewProductSEOForm';
import CustomTreeView from '../../treeView/CustomTreeView';
import { checkProductSku, createSeo, getLanguages, getZones, uploadFile } from '../../../server/server';
import { CreateSeoDto } from '../../../model/seo.dto';
import ProductTagsForm from './ProductTagsForm';
import { Product } from './NewProduct';

function ProductInformations({ product, setProduct, onSubmit, error, setError }: { product: Product; setProduct: any; onSubmit: any; error: any; setError: any }) {
	const [selectedLanguage, setSelectedLanguage] = useState<any>(!!product.language?.languageName ? product.language : { languageName: '' });
	const [languages, setLanguages] = useState<any[]>([]);
	const [selectedZone, setSelectedZone] = useState<any>(!!product.zone?.name ? product.zone : { name: '' });
	const [zones, setZones] = useState<any[]>([]);
	const [tags, setTags] = React.useState<any>([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const [selectedCategories, setSelectedCategories] = useState<any>(!!product.categories ? product.categories : []);
	const [imagesList, setImagesList] = useState<any>([]);
	const timeout = useRef<any>(null);

	useEffect(() => {
		loadZones();
		loadLanguages(paginationModel.page, paginationModel.pageSize);
	}, []);

	useEffect(() => {
		setSelectedLanguage(!!product.language?.name ? product.language : { languageName: '' });
		setSelectedZone(!!product.zone?.name ? product.zone : { name: '' });
		setImagesList(!!product.files ? product.files : []);
		setSelectedCategories(!!product.categories ? product.categories : []);
		setTagsList(!!product.tags ? product.tags : []);
	}, [product]);

	const loadLanguages = async (page: any, pageSize: any, searchQuery?: any) => {
		const response = await getLanguages(pageSize, page * pageSize, searchQuery);
		if (response.status == 200) {
			setLanguages(response.data.languages);
		} else {
		}
	};

	const loadZones = async () => {
		const resp = await getZones();
		if (resp.status === 200) {
			setZones(resp.data);
		}
	};

	const setTagsList = (list: string[]) => {
		const newList = list.map((item: any) => {
			return {
				id: item,
				text: item,
			};
		});
		setTags(newList);
	};

	const handleZoneChange = (event: any, newValue: any | null) => {
		setError({ ...error, zone: '' });
		setSelectedZone(newValue);
		setProduct({ ...product, zone: newValue });
	};

	const handleLanguageChange = (event: any, newValue: any | null) => {
		setError({ ...error, language: '' });
		setSelectedLanguage(newValue);
		setProduct({ ...product, language: newValue });
	};

	const defaultPropsZone = {
		options: zones,
		getOptionLabel: (option: any) => option?.name,
	};

	const defaultPropsLanguage = {
		options: languages,
		getOptionLabel: (option: any) => option?.name || '',
	};
	const setCategories = (list: any) => {
		setSelectedCategories(list);
		setProduct({ ...product, categories: list });
	};

	// Handling changes for product fields
	const handleFieldValuesChanges = (event: any, field: keyof Product) => {
		setError({ ...error, [field]: !!event.target.value ? '' : 'Required filed' });
		setProduct({ ...product, [field]: event.target.value });

		if (field === 'sku') {
			if (timeout.current != null) clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				isSkuAvailable(event.target.value);
			}, 700);
		}
	};

	const isSkuAvailable = async (text: string) => {
		const resp = await checkProductSku(text);
		if (resp.status === 200) {
			if (resp.data.exists) {
				setError({ ...error, sku: 'Sku already exists' });
			} else {
				setError({ ...error, sku: text ? '' : 'Required filed' });
			}
		}
	};

	return (
		<div className="px-5 mt-8 ">
			<div>
				<div className="grid grid-cols-3 gap-5 ">
					<div className="flex flex-row items-center  gap-5 ">
						<p>Language:</p>
						<div className="max-w-[250px] w-full">
							<Autocomplete {...defaultPropsLanguage} value={selectedLanguage} onChange={handleLanguageChange} renderInput={(params) => <TextField helperText={error.language ?? ''} error={!!error?.language} label="Select language" {...params} variant="standard" />} />
						</div>
					</div>
					<div className="flex flex-row items-center  gap-5 ">
						<p>Zone:</p>
						<div className="max-w-[250px] w-full">
							<Autocomplete {...defaultPropsZone} value={selectedZone} onChange={handleZoneChange} renderInput={(params) => <TextField helperText={error.zone ?? ''} error={!!error?.zone} label="Select zone" {...params} variant="standard" />} />
						</div>
					</div>
					<div className="">
						<p className="text-black text-lg ">Is this product featured?</p>
						<FormControl>
							<FormControlLabel control={<Checkbox checked={product.featured} onChange={(e: any) => setProduct({ ...product, featured: e.target.checked })} />} label="Featured" />
						</FormControl>
					</div>
				</div>
				<div className="mt-10 grid grid-cols-6 gap-5">
					<div className="col-span-4 grid grid-cols-6 gap-5">
						<div className="col-span-6">
							<TextField required className=" background-transparent " value={product.name} onChange={(e) => handleFieldValuesChanges(e, 'name')} helperText={error?.name ?? ''} error={!!error?.name} fullWidth label="Product name" variant="filled" />
						</div>
						<div className="col-span-2">
							<TextField required className=" background-transparent " value={product.sku} onChange={(e) => handleFieldValuesChanges(e, 'sku')} helperText={error?.sku ?? ''} error={!!error?.sku} fullWidth label="SKU" variant="filled" />
						</div>

						<div className="col-span-2">
							<TextField required className=" background-transparent" value={product?.urlSlug ?? ''} onChange={(e) => handleFieldValuesChanges(e, 'urlSlug')} helperText={error?.urlSlug ?? ''} error={!!error?.urlSlug} fullWidth label="Url Slug" variant="filled" />
						</div>
						<div className="col-span-2 flex flex-col items-center">
							<FormControl>
								<FormControlLabel control={<Checkbox checked={product.web} onChange={(e: any) => setProduct({ ...product, web: e.target.checked })} />} label="Web" />
								<FormControlLabel control={<Checkbox checked={product.mobile} onChange={(e: any) => setProduct({ ...product, mobile: e.target.checked })} />} label="Mobile" />
							</FormControl>
						</div>
					</div>

					<div className="flex flex-col col-span-2  gap-2 ">
						<label htmlFor="Categories">Category</label>
						<div className="border rounded ">
							<CustomTreeView setSelectedCategories={setCategories} selectedCategories={selectedCategories} />
						</div>
					</div>
					{/* </div> */}
					{/* <div className="mt-10 grid grid-cols-6 gap-5"> */}
				</div>
				<div className="mt-10 grid grid-cols-2 gap-5 ">
					<div className="">
						<p>Description</p>
						<MDEditor value={product.description} onChange={(newEditorState: any) => setProduct({ ...product, description: newEditorState })} height={270} />
					</div>
					<div className="">
						<p>Specification</p>
						<MDEditor value={product.specification} onChange={(newEditorState: any) => setProduct({ ...product, specification: newEditorState })} height={270} />
					</div>
				</div>
				<ProductTagsForm product={product} setProduct={setProduct} tags={tags} setTags={setTags} />
				<NewProductImagesForm imagesList={imagesList} setImagesList={setImagesList} product={product} setProduct={setProduct} />
				{/* <NewProductSEOForm product={product} setProduct={setProduct} /> */}
				<div className="flex  w-full flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
					<div className="flex flex-row items-end justify-end gap-10">
						<Button variant="contained" onClick={() => onSubmit(true)} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Save as draft
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							{!!product.id ? 'Confirm edit product' : 'Create new product'}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProductInformations;
