import { RadioGroup, FormControlLabel, FormControl, Radio, Checkbox, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomAllow, ProductType } from '../../../model/enums';
import PresentationPicture from './PresentationPicture';
import ProductDesignChooseList from './ProductDesignChooseList';
import FontChooseList from './FontChooseList';
import SymbolChooseList from './SymbolChooseList';
import ProductCommercialGroupsForm from './ProductCommercialGroupsForm';
import { Product } from './NewProduct';
import PersonalizationChooseModel from '../../modals/PersonalisationChoose';

function Personalization({ product, setProduct, onSubmit }: { product: Product; setProduct: any; onSubmit: any }) {
	const [showChooseModal, setShowChooseModal] = useState(false);
	const [displayDigitalFieldsList, setDisplayDigitalFieldsList] = useState<any>([]);
	const handleChange = (event: any) => {
		setProduct({ ...product, options: { ...product.options, allowPersonalization: event.target.value } });
	};
	const handleProductType = (event: any) => {
		setProduct({ ...product, options: { ...product.options, productType: event.target.value } });
	};
	const handlePrintingMethod = (event: any) => {
		setProduct({ ...product, options: { ...product.options, method: event.target.value } });
	};
	const handleEngravingColor = (event: any) => {
		setProduct({ ...product, options: { ...product.options, engravingColor: event.target.value } });
	};
	const handleDPList = () => {
		console.log('dpf', displayDigitalFieldsList);
		setProduct({ ...product, digitalProductFields: displayDigitalFieldsList });
	};

	useEffect(() => {
		if (product.digitalProductFields) {
			setDisplayDigitalFieldsList(product.digitalProductFields);
		}
	}, []);

	return (
		<div className="px-5 mt-8 ">
			<div>
				<div className="grid grid-cols-5 gap-5">
					<div>
						<FormControl>
							<p className="text-black text-lg ">Allow personalization</p>
							<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={product.options?.allowPersonalization} onChange={(e) => handleChange(e)}>
								<FormControlLabel value={CustomAllow.YES} control={<Radio />} label="Yes" />
								<FormControlLabel value={CustomAllow.NO} control={<Radio />} label="No" />
								<FormControlLabel value={CustomAllow['DIGITAL PRODUCT']} control={<Radio />} label="Digital product" />
							</RadioGroup>
						</FormControl>
					</div>
					<div className={`${product.options?.allowPersonalization == CustomAllow.YES ? 'visible' : 'invisible'}`}>
						<FormControl>
							<p className="text-black text-lg ">Type of personalization</p>
							<FormControlLabel control={<Checkbox checked={product.options?.text} onChange={(e: any) => setProduct({ ...product, options: { ...product.options, text: e.target.checked } })} />} label="Text" />
							<FormControlLabel control={<Checkbox checked={product.options?.image} onChange={(e: any) => setProduct({ ...product, options: { ...product.options, image: e.target.checked } })} />} label="Picture" />
							<FormControlLabel control={<Checkbox checked={product.options?.symbol} onChange={(e: any) => setProduct({ ...product, options: { ...product.options, symbol: e.target.checked } })} />} label="Symbol" />
						</FormControl>
					</div>
					<div className={`${product.options?.allowPersonalization == CustomAllow.YES ? 'visible' : 'invisible'}`}>
						<FormControl>
							<p className="text-black text-lg ">Product type </p>
							<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={product.options?.productType} onChange={(e) => handleProductType(e)}>
								<FormControlLabel value={ProductType.WOOD} control={<Radio />} label="Wood" />
								<FormControlLabel value={ProductType.LEATHER} control={<Radio />} label="Leather" />
								<FormControlLabel value={ProductType.GLASS} control={<Radio />} label="Glass" />
								<FormControlLabel value={ProductType.METAL} control={<Radio />} label="Metal" />
							</RadioGroup>
						</FormControl>
					</div>

					<div className={`${product.options?.allowPersonalization == CustomAllow['DIGITAL PRODUCT'] ? 'visible' : 'invisible'}`}>
						<p className="text-black text-lg ">Digital product fields</p>
						<Button variant="contained" onClick={() => setShowChooseModal(true)} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Choose fields
						</Button>
					</div>
					<div className={`${product.options?.allowPersonalization == CustomAllow.YES ? 'visible' : 'invisible'}`}>
						<FormControl>
							<p className="text-black text-lg ">Printing method</p>
							<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={product.options?.method} onChange={(e) => handlePrintingMethod(e)}>
								<FormControlLabel value={'Printing'} control={<Radio />} label="Printing" />
								<FormControlLabel value={'Engraving'} control={<Radio />} label="Engraving" />
							</RadioGroup>
							{product.options?.method == 'Engraving' && <TextField required className="background-transparent " value={product.options?.engravingColor} onChange={(e) => handleEngravingColor(e)} fullWidth label="Engraving color" variant="filled" />}
						</FormControl>
					</div>
				</div>
				<ProductCommercialGroupsForm product={product} setProduct={setProduct} />
				<ProductDesignChooseList product={product} setProduct={setProduct} />
				<FontChooseList product={product} setProduct={setProduct} />
				<SymbolChooseList product={product} setProduct={setProduct} />

				<PresentationPicture product={product} setProduct={setProduct} />

				<div className="flex  w-full flex-col mt-10 border border-mediumGrey rounded-md p-[30px] gap-5">
					<div className="flex flex-row items-center justify-end gap-10 ">
						<Button variant="contained" onClick={() => onSubmit(true)} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Save as draft
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
			<PersonalizationChooseModel
				modalIsOpen={showChooseModal}
				displayDigitalFieldsList={displayDigitalFieldsList}
				setDisplayDigitalFieldsList={setDisplayDigitalFieldsList}
				afterOpenModal={() => setShowChooseModal(true)}
				closeModal={() => setShowChooseModal(false)}
				confirmation={() => handleDPList()}
			/>
		</div>
	);
}

export default Personalization;
