import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddProductsImageModal from '../products/new-product/AddProductsImageModal';
import { createLanguage, editLanguage, getImage, getLanguageById, uploadFile } from '../../server/server';

type Language = {
	id: string;
	name: string;
	locale: string;
	code: string;
	textDirection: string;
	flagId: string;
	priority: number;
	disabled: boolean;
	flag: {
		id: string;
	};
};

enum TextDirection {
	LTR = 'LTR',
	RTL = 'RTL',
}

interface EditLanguageModalProps {
	open: boolean;
	onClose: () => void;
	languageId?: string | undefined;
}

const EditLanguageModal: React.FC<EditLanguageModalProps> = ({ open, onClose, languageId }) => {
	const [languageToEdit, setLanguageToEdit] = useState<Language | undefined>(undefined);

	const [languageFile, setLanguageFile] = useState<File>();
	const [editFlagURL, setEditFlagURL] = useState('');
	const [fileModalOpen, setFileModalOpen] = useState(false);

	const [isEdit, setIsEdit] = useState(!!languageId);

	const initialFormData = languageToEdit
		? {
				...languageToEdit,
				priority: languageToEdit.priority !== null ? languageToEdit.priority : undefined,
		  }
		: {
				name: '',
				locale: '',
				code: '',
				textDirection: 'LTR',
				flagId: '',
				priority: null as number | null,
				disabled: false,
		  };

	const [formData, setFormData] = useState(initialFormData);

	const textDirectionOptions = [
		{ label: 'Left to Right', value: TextDirection.LTR },
		{ label: 'Right to Left', value: TextDirection.RTL },
	];

	const [nameValid, setNameValid] = useState(true);
	const [localeValid, setLocaleValid] = useState(true);
	const [languageCodeValid, setLanguageCodeValid] = useState(true);
	const [priorityValid, setPriorityValid] = useState(true);

	const resetForm = () => {
		setLanguageToEdit(undefined);
		setLanguageFile(undefined);
		setEditFlagURL('');
		setIsEdit(false);
		setFileModalOpen(false);

		setFormData({
			name: '',
			locale: '',
			code: '',
			textDirection: 'LTR',
			flagId: '',
			priority: null,
			disabled: false,
		});

		setNameValid(true);
		setLocaleValid(true);
		setLanguageCodeValid(true);
		setPriorityValid(true);
	};

	useEffect(() => {
		resetForm();
		if (languageId) {
			fetchLanguageDetails(languageId);
		}
	}, [languageId, open]);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;

		if (name === 'priority') {
			// Ensure that priority is a valid positive number
			const isPriorityValid = /^[1-9]\d*$/.test(value);

			setPriorityValid(isPriorityValid);

			if (isPriorityValid) {
				setFormData({
					...formData,
					[name]: parseInt(value, 10),
				});
			} else {
				// If priority is not valid, set it to null
				setFormData({
					...formData,
					[name]: null,
				});
			}
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}

		// switch (name) {
		// 	case 'name':
		// 		setNameValid(true);
		// 		break;
		// 	case 'locale':
		// 		setLocaleValid(true);
		// 		break;
		// 	case 'code':
		// 		setLanguageCodeValid(true);
		// 		break;
		// 	case 'priority':
		// 		setPriorityValid(true);
		// 		break;
		// 	default:
		// 		break;
		// }
	};

	const fetchLanguageDetails = async (id: any) => {
		if (id) {
			const resp = await getLanguageById(id);

			if (resp.status == 200) {
				console.log(resp.data);
				setLanguageToEdit(resp.data);
				setEditFlagURL(getImage(resp.data?.flag?.id));
				setFormData({
					name: resp.data?.name || '',
					locale: resp.data?.locale || '',
					code: resp.data?.code || '',
					textDirection: resp.data?.textDirection || 'LTR',
					flagId: resp.data?.flag?.id || '',
					priority: resp.data?.priority || undefined,
					disabled: resp.data?.disabled || false,
				});

				setIsEdit(!!id);
			}
		}
	};

	const handleSubmit = async () => {
		let flagId: string | undefined;

		const isNameValid = formData.name.trim() !== '';
		const isLocaleValid = formData.locale.trim() !== '';
		const isLanguageCodeValid = formData.code.trim() !== '';

		const isPriorityValid = formData.priority !== null && formData.priority !== undefined;
		console.log(isNameValid);

		setNameValid(isNameValid);
		setLocaleValid(isLocaleValid);
		setLanguageCodeValid(isLanguageCodeValid);
		setPriorityValid(isPriorityValid);

		if (!isNameValid || !isLocaleValid || !isLanguageCodeValid || !isPriorityValid) {
			alert('Please fill out all required fields marked with *.');
			return;
		}

		// if (isEdit && !languageToEdit?.flag) {
		// 	const resp = await uploadFile({ file: languageFile });

		// 	if (resp.status === 201) {
		// 		flagId = resp.data.id;
		// 	}
		// } else if (languageFile) {
		// 	const resp = await uploadFile({ file: languageFile });

		// 	if (resp.status === 201) {
		// 		flagId = resp.data.id;
		// 	}
		// }

		const languageData = {
			id: languageToEdit?.id,
			languageName: formData.name,
			locale: formData.locale,
			flagId: formData.flagId || undefined,
			languageCode: formData.code,
			textDirection: formData.textDirection,
			priority: formData.priority,
			disabled: formData.disabled,
		};

		const resp = isEdit ? await editLanguage(languageData) : await createLanguage(languageData);

		if (resp.status === 200 || resp.status === 201) {
			alert(`Language ${isEdit ? 'updated' : 'created'} successfully`);
			setFormData(initialFormData);
			setLanguageFile(undefined);
			onClose();
		} else {
			alert(`Error ${isEdit ? 'updating' : 'creating'} language: ${resp.status}`);
		}
	};

	const handleImageChange = async (file: any) => {
		const resp = await uploadFile({ file: file });
		if (resp.status === 201) {
			setFileModalOpen(false);
			formData.flagId = resp.data.id;
		}
	};

	const openAddFileModal = () => {
		setFileModalOpen(true);
	};

	return (
		<div>
			<Dialog open={open} onClose={onClose} fullWidth maxWidth="md" style={{ zIndex: 6 }}>
				<DialogTitle>Add Language</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField label="Full name *" variant="outlined" fullWidth name="name" value={formData?.name} onChange={handleInputChange} error={!nameValid} helperText={!nameValid && 'Language name is required'} />
								<FormHelperText>The name how it is displayed on your site (for example: English)</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<TextField label="Locale *" variant="outlined" fullWidth name="locale" value={formData?.locale} onChange={handleInputChange} error={!localeValid} helperText={!localeValid && 'Language locale is required'} />
								<FormHelperText>WordPress Locale for the language (for example: en_US). You will need to install the .mo file for this language.</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<TextField label="Language code *" variant="outlined" fullWidth name="code" inputProps={{ maxLength: 8 }} value={formData?.code} onChange={handleInputChange} error={!languageCodeValid} helperText={!languageCodeValid && 'Language code is required'} />
								<FormHelperText>Language code - preferably 2-letters ISO 639-1 (for example: en).</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<FormControl component="fieldset" style={{ display: 'block' }} className="w-full">
									<label htmlFor="textDirection" className="mb-1">
										Text direction
									</label>
									<RadioGroup aria-label="textDirection" name="textDirection" value={formData?.textDirection} onChange={handleInputChange}>
										{textDirectionOptions.map((item) => (
											<FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
										))}
									</RadioGroup>
									<FormHelperText>Choose the text direction for the language</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<label htmlFor="flag-select" className="mb-1">
										Flag
									</label>

									<Button onClick={openAddFileModal} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold min-h-[61px]">
										Add flag
									</Button>
									{/* <input id="flag-select" type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e)} /> */}
									{formData.flagId && (
										<div className="mt-2">
											<img src={getImage(formData.flagId)} className="h-20" alt="Selected Flag" width="50" />
										</div>
									)}
									{/* {editFlagURL && !languageFile ? <img src={editFlagURL} className="h-20" alt="Selected Flag" width="50" /> : null} */}
									<FormHelperText>Choose a flag for the language</FormHelperText>
								</FormControl>
								<AddProductsImageModal modalIsOpen={fileModalOpen} closeModal={() => setFileModalOpen(false)} setSelectedFileToList={handleImageChange} isWithinLanguageModal={true} />
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Order *"
									variant="outlined"
									fullWidth
									name="priority"
									value={formData?.priority !== null ? formData?.priority?.toString() : ''}
									onChange={handleInputChange}
									onInput={(e) => {
										const inputValue = (e.target as HTMLInputElement).value;
										const sanitizedValue = parseInt(inputValue, 10) < 0 ? '0' : inputValue;

										// Update the formData
										setFormData((prevFormData) => ({
											...prevFormData,
											priority: sanitizedValue !== '' ? parseInt(sanitizedValue, 10) : null,
										}));
									}}
									error={!priorityValid}
									helperText={!priorityValid && 'Language priority is required and any real number'}
								/>
								<FormHelperText>Position of the language in the language switcher</FormHelperText>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="primary">
							Cancel
						</Button>
						<Button variant="contained" className="mr-2" color="primary" onClick={handleSubmit}>
							{isEdit ? 'Edit Language' : 'Add New Language'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
};

export default EditLanguageModal;
