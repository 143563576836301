import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import React, { useEffect, useRef, useState } from 'react';
import { IEvent } from 'fabric/fabric-impl';
import { FaImage } from 'react-icons/fa';
import { getImage, uploadFile } from '../../../server/server';
import { DEFAULT_HEIGHT, DEFAULT_LEFT, DEFAULT_TOP, DEFAULT_WIDTH, FAKE_RESTRICTION_AREA, VIRTUAL_CANVAS_SIZE } from '../../../Constants';
import { Button, TextField } from '@mui/material';
import Loading from '../../../common/Loading';

function PresentationPicture({ product, setProduct }: { product: any; setProduct: any }) {
	const { editor, onReady } = useFabricJSEditor();
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [canvasHeight, setCanvasHeight] = useState(300);
	const [scale, setScale] = useState(0.6);
	const [loading, setLoading] = useState(false);
	const [fieldError, setFieldError] = useState<any>({ height: '', width: '' });
	const [proportions, setProportions] = useState<any>({ width: 0.0, height: 0.0 });

	const updateDimensions = (obj: any) => {
		if (!!obj) {
			const left = obj.left ?? 0;
			const top = obj.top ?? 0;
			const currentWidth = obj.getScaledWidth() ?? 0;
			const currentHeight = obj.getScaledHeight() ?? 0;

			setProduct({ ...product, restricted: [Math.round(left / scale), Math.round(top / scale), Math.round(currentWidth / scale), Math.round(currentHeight / scale)] });
		}
	};

	const handleWChange = (e: any) => {
		setProportions({ ...proportions, width: e });
		setProduct({ ...product, pdfW: parseFloat(e) });
	};
	const handleHChange = (e: any) => {
		setProportions({ ...proportions, height: e });
		setProduct({ ...product, pdfH: parseFloat(e) });
	};

	useEffect(() => {
		setProportions({ width: product?.pdfW, height: product?.pdfH });
	}, []);

	useEffect(() => {
		if (!!product.restrictedImage) {
			setImage();
		}
		return () => {
			editor?.canvas.off();
		};
	}, [onReady]);

	const isLessThanZero = (num: any, msg: string) => {
		if (num <= 0) return msg;
		return '';
	};

	const onAddRectangle = () => {
		setFieldError({ width: isLessThanZero(proportions?.width, 'Width is required'), height: isLessThanZero(proportions?.height, 'Height is required') });
		if (editor?.canvas.isEmpty()) {
			alert('You must select an image before creating a rectangle.');
			return;
		} else if (editor?.canvas.getObjects('rect')?.length !== 0) {
			const rectangles: fabric.Object[] | undefined = editor?.canvas.getObjects('rect');
			rectangles?.forEach((item: fabric.Object) => {
				editor?.canvas.remove(item);
			});
		}
		// editor?.canvas.add(new fabric.Rect({ width: editor.canvas.getWidth(), height: editor.canvas.getHeight(), fill: 'rgba(128, 128, 128, 0.7)', top: 0, left: 0, selectable: false }));
		const restrictedArea = {
			width: proportions?.width ?? 10,
			height: proportions?.height ?? 10,
			left: proportions?.width ?? 10,
			top: proportions?.height ?? 10,
		};
		const rect = new fabric.Rect({
			width: restrictedArea.width,
			height: restrictedArea.height,
			fill: '#ffffff90',
			top: restrictedArea.top,
			left: restrictedArea.left,
			borderColor: '#ff0000',
			lockUniScaling: true,
		});
		setProduct({ ...product, restricted: [restrictedArea.left, restrictedArea.top, restrictedArea.width, restrictedArea.height] });

		rect.setControlsVisibility({
			mt: false, // Middle top control
			mb: false, // Middle bottom control
			ml: false, // Middle left control
			mr: false, // Middle right control
			mtr: false, // Rotating control
		});
		editor?.canvas.add(rect);

		rect.on('modified', function () {
			updateDimensions(rect);
		});
	};
	const onImageUpload = async (event: any) => {
		setFieldError({ width: isLessThanZero(proportions?.width, 'Width is required'), height: isLessThanZero(proportions?.height, 'Height is required') });

		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		setLoading(true);
		let file = event.target.files[0];
		if (!!file) {
			const resp = await uploadFile({ file: file, selectable: 'true' });
			if (resp.status == 201) {
				const uploadedImage = resp.data;

				setProduct({ ...product, restrictedImage: uploadedImage.id });

				setImageToRestrictedArea(file);
				setLoading(false);
			} else {
				setLoading(false);
				return;
			}
		}
	};
	const setImage = () => {
		const image = getImage(product.restrictedImage);
		const imgElement = new Image();
		imgElement.src = image as string;
		imgElement.onload = () => {
			const fabricImage = new fabric.Image(imgElement);
			const scaleX = VIRTUAL_CANVAS_SIZE / (fabricImage.width ?? 1);
			const scaleY = VIRTUAL_CANVAS_SIZE / (fabricImage.height ?? 1);
			const scale = Math.min(scaleX, scaleY);
			setScale(scale);
			fabricImage.scale(scale);

			// fabricImage.scale(scale);
			editor?.canvas.setWidth(fabricImage.getScaledWidth());
			editor?.canvas.setHeight(fabricImage.getScaledHeight());

			fabricImage.selectable = false;
			editor?.canvas.clear();
			// editor?.canvas.add(fabricImage);
			editor?.canvas?.setBackgroundImage(fabricImage, editor?.canvas?.requestRenderAll.bind(editor?.canvas), {});
			// editor?.canvas.add(new fabric.Rect({ width: editor.canvas.getWidth(), height: editor.canvas.getHeight(), fill: 'rgba(128, 128, 128, 0.6)', top: 0, left: 0, selectable: false }));
			loadRestrictedRect();
		};
	};
	const loadRestrictedRect = () => {
		let restricted = product.restricted?.map((c: any) => c * scale);
		console.log('bef', restricted);

		if (restricted?.length === 0) {
			console.log('here');
			restricted = [(proportions?.width ?? 10) * 0.15, (proportions?.height ?? 10) * 0.15, (proportions?.width ?? 10) * 8, (proportions?.height ?? 10) * 8];
			console.log('here', restricted);
		}
		console.log('aftr', restricted);
		const rect = new fabric.Rect({ width: restricted?.[2], height: restricted?.[3], fill: '#ffffff70', top: restricted?.[1], left: restricted?.[0], borderColor: '#ff0000', strokeWidth: 1, stroke: '#ff0000' });
		rect.setControlsVisibility({
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			mtr: false,
		});
		editor?.canvas.add(rect);
		rect.on('modified', function () {
			updateDimensions(rect);
		});
		editor?.canvas.renderAll();
	};

	const setImageToRestrictedArea = async (file: any) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const imgElement = new Image();
			imgElement.src = event?.target?.result as string;

			imgElement.onload = () => {
				const fabricImage = new fabric.Image(imgElement);
				const scaleX = VIRTUAL_CANVAS_SIZE / (fabricImage.width ?? 1);
				const scaleY = VIRTUAL_CANVAS_SIZE / (fabricImage.height ?? 1);
				const scale = Math.min(scaleX, scaleY);
				setScale(scale);
				fabricImage.scale(scale);
				editor?.canvas.setWidth(fabricImage.getScaledWidth());
				editor?.canvas.setHeight(fabricImage.getScaledHeight());
				// editor?.canvas.on('mouse:wheel', (e: IEvent<WheelEvent>) => {
				// 	e.e.preventDefault();

				// 	// Trenutni faktor zumiranja i faktor promene zumiranja
				// 	const currentZoom = editor.canvas.getZoom();
				// 	const delta = e.e.deltaY;

				// 	// Podesite faktor promene zumiranja prema potrebi
				// 	const zoomFactor = delta > 0 ? 0.9 : 1.1;

				// 	// Novi faktor zumiranja
				// 	const newZoom = currentZoom * zoomFactor;

				// 	// Postavite  novi faktor zumiranja
				// 	editor.canvas.setZoom(newZoom);
				// 	editor.canvas.requestRenderAll();
				// });

				// fabricImage.set({
				// 	left: 0,
				// 	top: 0,
				// 	scaleX: scale,
				// 	scaleY: scale,
				// });
				fabricImage.selectable = false;
				editor?.canvas.clear();
				editor?.canvas.add(fabricImage);
				editor?.canvas.renderAll();
			};
		};
		reader.readAsDataURL(file);
	};
	return (
		<div className="flex w-full flex-row mt-10 border border-mediumGrey rounded-md p-[30px] gap-5  h-full" key={product}>
			<div className="flex flex-col items-center h-full">
				<p className=" text-darkGrey text-lg  px-2">Printable area</p>
				<div className="flex flex-col gap-3">
					<div>
						<div className=" w-[250px]">
							<TextField
								required
								className=" background-transparent "
								value={proportions?.width}
								onFocus={() => setFieldError({ ...fieldError, width: '' })}
								onChange={(e) => handleWChange(e.target.value)}
								helperText={fieldError?.width ?? ''}
								error={!!fieldError?.width}
								fullWidth
								label="Width in cm"
								variant="filled"
							/>
						</div>
						<div className=" w-[250px]">
							<TextField
								required
								className=" background-transparent "
								value={proportions?.height}
								onFocus={() => setFieldError({ ...fieldError, height: '' })}
								onChange={(e) => handleHChange(e.target.value)}
								helperText={fieldError?.height ?? ''}
								error={!!fieldError?.height}
								fullWidth
								label="Height in cm"
								variant="filled"
							/>
						</div>
					</div>
					<div className="flex flex-col gap-6 mt-4">
						<div className="">
							<input type="file" accept="image/*" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
							<Button variant="outlined" style={{ backgroundColor: '#eaeaea', color: '#555', fontWeight: 600, border: '1px solid #777777', minWidth: 250 }} disabled={loading} onClick={() => fileInputRef.current?.click()}>
								{!loading ? (
									<div className="flex flex-row items-center justify-center">
										<FaImage />
										<span className="ml-3">Upload Image</span>
									</div>
								) : (
									<Loading height={18} />
								)}
							</Button>
						</div>
						<div>
							<Button variant="outlined" style={{ backgroundColor: '#eaeaea', color: '#555', fontWeight: 600, border: '1px solid #777777', minWidth: 250 }} onClick={() => onAddRectangle()}>
								Add rectangle
							</Button>
						</div>
					</div>
				</div>
			</div>

			<div className="h-full">
				<FabricJSCanvas onReady={onReady} className="w-[500px] h-[500px] fabric-container flex items-center justify-center" />
				{/* {scale} */}
				{/* <br /> */}
				{/* {getImage(product.restrictedImage)} */}
			</div>
		</div>
	);
}

export default PresentationPicture;
